<template>
    <div class="box">
        <h2>
            URL zu iFrame Zuordnung für MS Proxy
            <small>Mit dieser Zuordnung können fixe URLs einem iFrame zugeordnet werden, ohne dass dort dann die iFrame ID angegeben werden muss.</small>
        </h2>
        <div class="body">
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(zuordnung, index) in mapping" v-bind:key="index">
                        <td>
                            <div class="form-group form-float no-error">
                                <input v-model="mapping[index].url" :name="'zuordnung_url_' + index" v-validate="'required|url'" :id="'zuordnung_url_' + index" placeholder=" " type="text" />
                                <label :for="'zuordnung_url_' + index">URL</label>
                                <span class="error" v-show="errors.has('zuordnung_url_' + index)">{{ errors.first("zuordnung_url_" + index) }}</span>
                            </div>
                        </td>
                        <td>
                            <div class="form-group form-float no-error">
                                <input v-model="mapping[index].wlid" :name="'zuordnung_wlid_' + index" v-validate="'required'" :id="'zuordnung_wlid_' + index" placeholder=" " type="text" />
                                <label :for="'zuordnung_wlid_' + index">iFrame Identifier</label>
                                <span class="error" v-show="errors.has('zuordnung_wlid_' + index)">{{ errors.first("zuordnung_wlid_" + index) }}</span>
                            </div>
                        </td>
                        <td><i @click="removeMapping(index)" class="delete fal fa-trash-alt"></i></td>
                    </tr>
                    <tr>
                        <td colspan="3">
                            <span class="add" @click="addMapping()"><i class="add fal fa-plus-circle"></i> Hinzufügen</span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <hr />
            <div class="button-row">
                <button @click="save()" type="button" class="primary size-l"><i class="fal fa-save"></i> Speichern</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            mapping: []
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        addMapping() {
            this.mapping.push({ url: "", wlid: "" });
        },
        removeMapping(index) {
            this.mapping.splice(index, 1);
        },
        save() {
            var mapping = [];
            for (let f = 0; f < this.mapping.length; f++) {
                if (this.mapping[f].url.trim() == "") {
                    continue;
                }

                mapping.push(this.mapping[f]);
            }

            this.$api.put("/ktvm/mapping_ms", { mapping: mapping }).then(
                () => {
                    this.$snotify.success("Zuordnung gespeichert");
                },
                () => {
                    this.$snotify.error("Speichern fehlgeschlagen");
                }
            );
        },
        fetchData() {
            this.$api.get("/ktvm/mapping_ms").then(
                response => {
                    if (response.data.data === null) {
                        response.data.data = {
                            mapping: []
                        };
                    }
                    if (response.data.data.mapping === null) {
                        response.data.data.mapping = [];
                    }

                    this.mapping = response.data.data.mapping;
                },
                () => {
                    this.$snotify.error("Fehler beim Laden des Mappings");
                }
            );
        }
    }
};
</script>
